import React from "react"
import {graphql} from 'gatsby'
import Card from 'components/blog/card';
import Layout from 'components/layout/internal';
import Link from 'shared/link'
import MastheadInterior from 'components/masthead/interior'
import SEO from 'shared/seo'

var canUseDOM = !!(
	(typeof window !== 'undefined' &&
	window.document && window.document.createElement)
);

const BlogPostAll = ({data, pageContext}) => {
	// pageContext.header = 'home_hero.png';
	let defaultTopic = null;
	if (pageContext._type === 'BlogTopic' && pageContext.permalink) {
		for (const { node } of data.allBlogTopic.edges) {
			if (node.permalink === pageContext.permalink)defaultTopic = node.id;
		}
	}

	var contentPages = data.allContentPage.edges;

	var page = contentPages.filter(page => (page.node.uri === '/blog/' || page.node.uri === '/en/blog/'));
	if (page.length) {
		page = page[0].node;
	}

	// Looping through listings to get categories
	const categories = data.allBlogTopic.edges;
	var categoryFilters = [];
	var cFilter = {};
	categories.forEach(cf =>{
		if(cf.node.id !== '5e44a1b7d2f57dd91a0f9ea5') {
			cFilter = { value: cf.node.id, label: cf.node.name };
			categoryFilters.push(cFilter);
		}
	})

	let allData = data.allBlogPost.edges;

	if (defaultTopic) {
		allData = allData.filter(({ node }) => {
			if (node.Topic.id === defaultTopic)return true;
			if (Array.isArray(node.Topic)) {
				for (const topic of node.Topic) {
					if (topic.object.id === defaultTopic)return true;
				}
			}
			return false;
		});
	}

	return (
		<>
		<Layout>
			<MastheadInterior title="Blog" intro="Etiam ultricies tortor mattis, mollis lorem eu, rhoncus nunc. Integer vel felis hendrerit, iaculis nisi" />
			<hr />
			<section className="grid-container">
				<div className="grid-x medium-up-3">
					{allData.map((card, index) => (
						<div className="cell">
							<Card key={index} {...card.node} />
						</div>
					))}
				</div>
			</section>
		</Layout>
		<SEO title="Read the Heritage Medical Blog" />
		</>
	)
}

export default BlogPostAll

export const query = graphql`
	query {
	  allBlogPost(sort: {order: DESC, fields: showDate}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	      	_model
	        id
	        photo
	        title
	        permalink
	        uri
	        intro
	        Topic {
	          object {
	          	id
	          	name
	          	permalink
	          }
	        }
	      }
	    }
	  }
	  allBlogTopic(sort: {order: ASC, fields: name}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        name
	        permalink
	      }
	    }
	  }
	  allContentPage(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
			uri
			pageTitle
	      }
	    }
	  }
	}
`
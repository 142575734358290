import React, {Component} from 'react'
import style from './mastheadInterior.module.scss';

class MastheadInterior extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		var title = '';
		if (this.props.title) {
			title = this.props.title;
		}
		var intro = '';
		if (this.props.intro) {
			intro = this.props.intro;
		}
		return (
			<div className={[style.container, "grid-container"].join(' ')}>
				<div className="grid-x">
					<div className="cell medium-6 medium-offset-3 text-center">
						<h1 className={style.title}>{title}</h1>
						<p className={style.intro}>{intro}</p>
					</div>
				</div>
			</div>
		)
	}
}

export default MastheadInterior;